<template>
  <div style="padding-right: 20px">
    <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-form">
      <el-form-item label="姓名" prop="addrName">
        <el-input v-model="form.addrName" placeholder="请填写姓名"></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="contactPhone">
        <el-input v-model="form.contactPhone" maxlength="11" placeholder="请填写手机号码"></el-input>
      </el-form-item>
      <el-form-item class="arae-item" label="地区" prop="provinceAllCode">
        <el-cascader
          v-model="form.provinceAllCode"
          :options="areaOptions"
          placeholder="请选择地区"
          @change="hanldArea"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="详细地址" prop="addressDetail">
        <el-input v-model="form.addressDetail" placeholder="请填写详细地址"></el-input>
      </el-form-item>
    </el-form>
    <div class="footer">
      <el-button type="primary" @click="onSubmit">确定</el-button>
      <el-button @click="onClose">关闭</el-button>
    </div>
  </div>
</template>

<script>
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
export default {
  name: "PersonAdd",
  props: {
    dialogParams: Object,
    default: {},
  },
  data() {
    const validateUser = (rule, value, callback) => {
    if (!value){
        callback(new Error('请输入11位手机号'))
        return
    }
    if(value.length<11||!(/^[1][0-9][0-9]{9}$/.test(value))) {
        callback(new Error('请输入正确的手机号码'))
    }else {
        callback()
    }
    };
    return {
      debug: 1,
      isRepeat: false,
      areaOptions: regionData,

      form: {
          addrName: null,     //姓名
          contactPhone: null,        //手机号码
          provinceAllCode: null,
          provinceAllname: null,
          addressDetail: null,
          checked: '0',
      },
      rules: {
        addrName: {
          required: true,
          message: "请填写姓名",
          change: "blur",
        },
        contactPhone: {
          required: true,
          change: "blur",
          validator: validateUser, 
        },
        provinceAllCode: {
          required: true,
          message: "请选择地区",
          trigger: "change",
        },
        addressDetail: {
          required: true,
          message: "请填写详细地址",
          change: "blur",
        },
      },
    };
  },

  methods: {
    hanldArea(val) {
      this.form.provinceAllname = val.map((item) => {
        return CodeToText[item];
      });
    },

    submit() {
      let formData = JSON.parse(JSON.stringify(this.form));
      formData.attribute = 1
      let params = {
        steelAddressInfoVOIOV: formData,
        summaryId: this.dialogParams.id
      };

      this.isRepeat = true;
      this.$api
        .address_addAddressInfoFromPC(params)
        .then((res) => {
          this.isRepeat = false;
          if (res) {
            this.$notify({
              title: "成功",
              message: "新增地址成功",
              type: "success",
            });
            this.onClose(true);
          }
        })
        .catch((err) => {
          this.isRepeat = false;
        });
    },

    onSubmit() {
      if (this.isRepeat) return;
      this.log(this.form);
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          this.log("error submit!!");
          return false;
        }
      });
    },
    onClose(val) {
      this.$emit("visible", val);
    },
  },
};
</script>

<style lang="sass" scoped>
.footer 
  margin-top: 50px;
  display: flex;
  justify-content: center;

/deep/ .el-select,
/deep/ .el-cascader--mini 
  width: 100%;

</style>